/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from '@emotion/react'
import SliderRede from '@/components/SliderRede'
import { Fragment } from "react";
import LogoRedeBertoni from '../assets/images/lp/home/logo-rede-bertoni.svg'

import { 
  SliderContent,
  logoRede
}  from '../assets/styles/LP.styles'

const ContentSection = () => {
  return (
    <Fragment>
      <div css={logoRede}>
        <img src={LogoRedeBertoni} />
      </div>
      <section css={SliderContent}>
        <Container fluid>
          <Row>
            <Col lg={12} md={12} sm={12} style={{ padding: 0 }}>
              <SliderRede />
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default ContentSection;
