import React from "react"
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/swiper-bundle.min.css";

export default () => {
  const carouselOptions = {
    spaceBetween: 0,
    loop: true,
    slidesPerView: 1,
    pagination: {
      el: "#team-carousel-pagination",
      type: "bullets",
      clickable: true,
    },
    breakpoints: {
      0: {
        spaceBetween: 0,
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
      576: {
        spaceBetween: 30,
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      992: {
        spaceBetween: 30,
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
      1200: {
        spaceBetween: 0,
        slidesPerView: 3,
        slidesPerGroup: 1,
      },
    },
  };
  return (
    <Swiper
      {...carouselOptions}
    >
      <SwiperSlide>
        <div className="bgPro">
          <div className="itembox">
            <h3>Bertoni PRO</h3>
            <a href="https://bertonipro.com.br">ACESSE O SITE</a>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="bgElos">
          <div className="itembox">
            <h3>Bertoni Elos</h3>
            <a href="https://bertonielos.com.br">ACESSE O SITE</a>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="bgBert">
          <div className="itembox">
            <h3>Berts International</h3>
            <a href="https://bertinternational.com.br/">ACESSE O SITE</a>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};