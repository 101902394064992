import React from "react"
import Layout from "@/components/layout"
import HeaderSection from "@/components/HeaderContent"

const IndexPage = () => {
  return (
    <Layout>
      <HeaderSection />
    </Layout>
  );
};

export default IndexPage;
